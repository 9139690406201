var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-box',[_c('v-box',{staticClass:"m-10 bg-white rounded box p-10",attrs:{"h":"full","shadow":"md"}},[_c('span',{staticClass:"underline cursor-pointer text-sm text-grey-100",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")]),_c('div',{staticClass:"flex justify-between items-center mb-4"},[_c('p',{staticClass:"text-2xl text-primary font-medium"},[_vm._v(" Consigner le remboursement des bénéficiaires ")]),_c('div',[_c('v-button',{attrs:{"disabled":(_vm.reviewed && _vm.hasErrors) || _vm.items.length === 0,"type":"button","color":"secondary","small":"","block":""},on:{"click":_vm.submit}},[_c('span',{staticClass:"normal-case font-bold"},[_vm._v("Soumettre ")])])],1)]),_c('hr'),_c('div',[_c('div',{staticClass:"max-w-3xl mx-auto"},[(_vm.validationFailed)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"error"}},[_vm._v(" Une erreur est survenue lors de la validation du fichier de remboursement. Veuillez reprendre s'il vous plait. ")]):_vm._e(),(_vm.networkError)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"error"}},[_vm._v(" Une erreur réseau est survenue lors de la récupération du rapport de validation du fichier de remboursement. Vérifiez que vous avez une connexion stable et réessayez. ")]):_vm._e()],1),(!_vm.loadingReport)?_c('div',[_c('v-data-table',{staticClass:"mt-6",attrs:{"expandable":"","hide-table-header":"","items":_vm.items,"columns":_vm.columns,"row-class":_vm.rowClass,"height":"100%"},scopedSlots:_vm._u([{key:"_iteration",fn:function(ref){
var iteration = ref.iteration;
return [_vm._v(" "+_vm._s(iteration)+" ")]}},{key:"date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.date,'DD/MM/YYYY HH:mm')))]):_vm._e()]}},{key:"row-details",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('ul',{staticClass:"py-1 ml-6 text-red-300 list-disc list-inside"},[(_vm.checkingErrorKey(index, 'RV-01'))?_c('li',[_vm._v(" Le groupe renseigné est invalide ou ne fait pas partie des groupes de ce dispatching ")]):_vm._e(),(_vm.checkingErrorKey(index, 'RV-02'))?_c('li',[_vm._v(" Le numéro de téléphone renseigné n'est pas valide ou n'appartient à aucun bénéficiare de ce dispatching ")]):_vm._e(),(_vm.checkingErrorKey(index, 'RV-03'))?_c('li',[_vm._v(" L'échéance de remboursement est invalide ")]):_vm._e(),(_vm.checkingErrorKey(index, 'RV-04'))?_c('li',[_vm._v(" La somme du montant à rembourser des membres du groupe doit être supérieur à 0 et ne doit pas excéder le montant dû du groupe ("+_vm._s(item.amountDue)+") ")]):_vm._e(),(_vm.checkingErrorKey(index, 'RV-05'))?_c('li',[_vm._v(" La date de remboursement n'est pas une date valide ou dépasse la date d'aujourd'hui ")]):_vm._e()])]}}],null,false,3439396411)})],1):_vm._e(),(_vm.loadingReport)?_c('v-flex',{attrs:{"direction":"col","align":"center","py":"8"}},[_c('v-spinner',{attrs:{"color":"secondary","width":"4","size":"6xl","speed":"1000"}}),_c('p',{staticClass:"mt-4"},[_vm._v("Traitement du fichier de remboursement en cours")]),_c('p',{staticClass:"mt-2"},[_vm._v("Veuillez patienter...")])],1):_vm._e()],1)]),_c('v-modal',{attrs:{"content-class":"max-w-xs","active":_vm.confirmModalActive,"title":"Soumission de fichier","okActionText":"Oui, soumettre"},on:{"update:active":function($event){_vm.confirmModalActive=$event},"action:ok":_vm.submit}},[_c('v-text',[_vm._v(" Vous êtes sur le point de soumettre le fichier de remboursement des bénéficiaires. Êtes-vous sûr ? ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }