











































































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import ReimbursementFile from '../../../../../../store/models/ReimbursementFile'
export default Vue.extend({
  data() {
    return {
      loading: false,
      loadingReport: false,
      confirmModalActive: false,
      showFaillureAlert: false,
      items: [],
      hasErrors: false,
      reviewed: false,
      validationFailed: false,
      networkError: false,
      reports: [],
      columns: [
        { label: '#', field: '_iteration', sortable: false },
        { label: 'Nom du groupe', mapping: 0, field: 'group' },
        { label: 'Nom du Bénéficiaire', mapping: 1, field: 'name' },
        { label: 'Numéro de téléphone', mapping: 2, field: 'phone' },
        {
          label: 'Montant à rembourser',
          mapping: 3,
          currency: true,
          field: 'amount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Date de remboursement',
          mapping: 4,
          field: 'date',
          date: true
        },
        {
          label: 'Montant Total Dû du groupe',
          mapping: 5,
          currency: true,
          field: 'amountDue',
          format: (x: any) => parseInt(x, 10)
        }
      ]
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    reimbursementFile() {
      return ReimbursementFile.find(this.$route.params.reimbursementFileId)
    }
  },

  methods: {
    async submit(this: any) {
      if (!this.confirmModalActive) {
        this.confirmModalActive = true
        return
      }
      await ReimbursementFile.api()
        .launch(this.$route.params.reimbursementFileId)
        .then(() => {
          this.$router.push({
            name: 'finances.refunds'
          })
        })
    },
    checkingErrorKey(this: any, index: number, key: string) {
      const report: any = this.reports.find((r: any) => r.key === key)
      return report && report.rows.includes(index)
    },
    rowClass(this: any, item: any, i: number) {
      const hasErrors = this.reports.some((report: any) => {
        if (!report.rows) {
          return false
        }
        return Array.isArray(report.rows)
          ? report.rows.includes(i)
          : Object.keys(report.rows).includes(String(i))
      })
      return hasErrors ? 'bg-red-100' : ''
    },
    getReport(id: string) {
      return new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          try {
            const res = await ReimbursementFile.api().report(id)
            if (res.response.data.reviewed) {
              clearInterval(timer)
              resolve(res.response.data)
            }
            console.log(res.response.data)
          } catch (error) {
            reject(error)
          }
        }, 2000)
      })
    }
  },
  async created(this: any) {
    if (!this.reimbursementFile) {
      console.log('rfv')

      ReimbursementFile.api().fetchOne(this.$route.params.reimbursementFileId)
    }

    this.loadingReport = true

    this.getReport(this.$route.params.reimbursementFileId)
      .then((response: any) => {
        this.loadingReport = false
        this.hasErrors = ['failed', 'invalid'].includes(response.reportStep)
        this.reviewed = response.reviewed
        this.validationFailed = response.reportStep === 'failed'
        this.items = response.items
        this.reports = response.reports
      })
      .catch((error: any) => {
        this.loadingReport = false
        this.networkError = /Network Error/.test(error.message)
        this.validationFailed = !this.networkError
      })
  }
})
